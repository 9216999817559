@import 'stream-chat-react/dist/css/v2/index.css';

.str-chat {
  --str-chat__spacing-0_5: 4px;
  --str-chat__spacing-8: 8px;
  --str-chat__spacing-10: 32px;
  --str-chat__message-bubble-border-radius: 8px;
  --str-chat__message-textarea-border-radius: 8px;
  --str-chat__message-textarea-background-color: #e8e7e7; /* compound.secondary.grey200 */
  --str-chat__secondary-surface-color: #e8e7e7; /* compound.secondary.grey200 */
  --str-chat__background-color: #fff; /* shared.white */
  --str-chat__secondary-background-color: #fff; /* shared.white */
  --str-chat__message-bubble-color: #000; /* shared.neutral.black */
  --str-chat__own-message-bubble-color: #fff; /* compound.secondary.cream400 */
  --str-chat__primary-surface-color: #140600; /* compound.secondary.brown500 */
  --str-chat__font-family: Helvetica Neue, ui-sans-serif;
  --str-chat__quoted-message-bubble-background-color: #ffffff;
  --str-chat__body2-text: 400 0.875rem/150% var(--str-chat__font-family);
  --str-chat__message-input-tools-color: #133f26;
  --str-chat__message-send-color: #140600; /* compound.secondary.brown500 */
  --str-chat__loading-indicator-color: #140600; /* compound.secondary.brown500 */
}

.str-chat__message--me .str-chat__message-bubble {
  border-block-start: none;
  border-block-end: none;
  border-inline-start: none;
  border-inline-end: none;
}

.str-chat__message-url-link {
  @apply underline cursor-pointer text-secondary-brown-500 !important;
  font-weight: 500 !important;
}

.str-chat__message
  .str-chat__message-inner
  .str-chat__message-bubble
  .str-chat__message-text
  p:not(:first-of-type),
.str-chat__quoted-message-preview
  .str-chat__message-inner
  .str-chat__message-bubble
  .str-chat__message-text
  p:not(:first-of-type) {
  margin: 12px 0 0;
}

.str-chat__message-attachment__voice-recording-widget__title,
.str-chat__attachment-preview-file-name,
.str-chat__attachment-list
  .str-chat__message-attachment__voice-recording-widget
  .str-chat__message-attachment__voice-recording-widget__right-section {
  display: none;
}

.str-chat-unread-message-separator-text {
  color: #000000 !important;
}

.str-chat-channel,
.str-chat__channel .str-chat__container {
  border: 1px solid transparent;
  border-radius: var(--str-chat__border-radius-sm);
}

.str-chat__channel .str-chat__container {
  overflow: hidden;
}
